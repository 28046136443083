import * as types from 'Actions/types'
import { combineReducers } from 'redux'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const candidateReducer = createReducer(initialState.share.candidate, {
  [types.SHARE_GET_CANDIDATE_SUCCEED]: (state, { data }) => {
    if (data === null) {
      return initialState.share.candidate
    }
    return {
      ...state,
      data: data || {},
    }
  },
  [types.SHARE_UPDATE_CANDIDATE]: (state, { data }) => {
    return {
      ...state,
      data: { ...state.data, ...data },
    }
  },
  [types.SHARE_GET_NOTES_SUCCEED]: (state, { data }) => ({
    ...state,
    notes: data.notes || [],
    evaluation: data.evaluation,
  }),
})
const candidatesReducer = createReducer(initialState.share.candidates, {
  [types.SHARE_GET_CANDIDATES_SUCCEED]: (state, { data }) => data || [],
})

const companyReducer = createReducer(initialState.share.company, {
  [types.SHARE_SET_SHARE]: (state, { data }) => data.company || {},
})

const currenciesReducer = createReducer(initialState.share.currencies, {
  [types.SHARE_GET_CURRENCIES_SUCCEED]: (state, { data }) => data || [],
})

const infoReducer = createReducer(initialState.share.info, {
  [types.SHARE_SET_SHARE]: (state, { data }) => ({
    ...state,
    ...(data.info || {}),
  }),
  [types.SHARE_SET_NOT_FOUND]: (state, { value }) => ({
    ...state,
    notFound: value,
  }),
  [types.SHARE_SET_ID]: (state, { value }) => ({
    ...state,
    id: value,
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.share.info,
})

const jobReducer = createReducer(initialState.share.job, {
  [types.SHARE_SET_SHARE]: (state, { data }) => data.job || {},
})

const phasesReducer = createReducer(initialState.share.phases, {
  [types.SHARE_GET_PHASES_SUCCEED]: (state, { data }) => data || [],
})

const reasonsReducer = createReducer(initialState.share.reasons, {
  [types.SHARE_GET_REJECT_REASONS_SUCCEED]: (state, { data }) => data || [],
})

const sharesReducer = createReducer(initialState.share.shares, {
  [types.SHARE_SET_SHARE]: (state, { data }) => data.shares || [],
})

const userReducer = createReducer(initialState.share.user, {
  [types.SHARE_SET_SHARE]: (state, { data }) => data.user || {},
})

const viewerReducer = createReducer(initialState.share.viewer, {
  [types.SHARE_SET_SHARE]: (state, { data }) => data.viewer || {},
})

export default combineReducers({
  candidate: candidateReducer,
  candidates: candidatesReducer,
  company: companyReducer,
  currencies: currenciesReducer,
  info: infoReducer,
  job: jobReducer,
  phases: phasesReducer,
  reasons: reasonsReducer,
  shares: sharesReducer,
  user: userReducer,
  viewer: viewerReducer,
})
